import { get } from 'lodash';

export const DEFAULT_IMG_1440 = 'https://placehold.co/1440x540/CACBCE/EEEEEE';
export const DEFAULT_ICON_200x200 = 'https://placehold.co/200x200/CACBCE/EEEEEE';
export const DEFAULT_ICON_80x80 = 'https://placehold.co/80x80/CACBCE/EEEEEE';
export const DEFAULT_ICON_32x32 = 'https://placehold.co/32x32/CACBCE/EEEEEE';
export const DEFAULT_ICON_16x16 = 'https://placehold.co/16x16/CACBCE/EEEEEE';
export const SCREEN_MIN_WIDTH = 992;
export const BREADCRUMB_URL_KEY = 'parent';
export const BREADCRUMB_DISPLAY_TEXT = 'breadcrumbDisplayText';
export const INDUSTRY = 'industry';
export const LINE_OF_BUSINESS = 'lineOfBusiness';
export const SOLUTIONS = 'solutions';
export const BUSINESS_NEED = 'businessNeed';
export const COMPANY = 'company';
export const PERSONA = 'persona';
export const CAMPAIGN = 'campaign';
export const TOPIC = 'topic';
export const HUBSPOT_URL = '/api/HubSpot/HubSpotFormPost/';
export const REACT_APP_TS_HUBSPOT = '/api/HubSpot/HubSpotFormPost/';
export const CONTENT_TYPE = 'contentType';
export const CONTENT_TYPE_PODCAST = 'podcast';
/** * If Experience Editor it crashes, as orininally React code reads from process.env which is undefined/null in Experience Editor. So , for time being this const has been introduced. */
export const specialLanguageMap = {
  'th/en': 'th',
  'vn/en': 'vi',
  'es/en': 'es',
  'gr/en': 'el',
  'it/en': 'it',
  'pt/en': 'pt',
  'no/en': 'nb',
  'tr/en': 'tr',
  'sk/en': 'sk',
  'mx/en': 'en',
  'pe/en': 'en',
  'py/en': 'en',
  'ro/en': 'ro',
  'kr/en': 'ko',
  'ar/en': 'en',
  'br/en': 'en',
  'cl/en': 'en',
  'co/en': 'en',
  'cr/en': 'en',
  'uy/en': 'en',
  'ae/en': 'ar',
  'cz/en': 'cs',
  'jp/en': 'ja',
  'fr/en': 'fr',
  'pl/en': 'pl',
  'th/en/': 'th',
  'vn/en/': 'vi',
  'es/en/': 'es',
  'gr/en/': 'el',
  'it/en/': 'it',
  'pt/en/': 'pt',
  'no/en/': 'nb',
  'tr/en/': 'tr',
  'sk/en/': 'sk',
  'mx/en/': 'en',
  'pe/en/': 'en',
  'py/en/': 'en',
  'ro/en/': 'ro',
  'kr/en/': 'ko',
  'ar/en/': 'en',
  'br/en/': 'en',
  'cl/en/': 'en',
  'co/en/': 'en',
  'cr/en/': 'en',
  'uy/en/': 'en',
  'ae/en/': 'ar',
  'cz/en/': 'cs',
  'jp/en/': 'ja',
  'fr/en/': 'fr',
  'pl/en/': 'pl',
};

export const globalSiteRedirectionMap = {
  de: 'de',
  fr: 'fr',
  en: 'en',
  nl: 'nl',
  es: 'es',
  it: 'it',
};

export const languageMap = {
  de: 'de',
  fr: 'fr',
  en: 'en',
  nl: 'nl',
  es: 'es',
  it: 'it',
};

export const countryDefaultLanguageMap = {
  DEU: 'de',
  FRA: 'fr',
  NLD: 'nl',
  ESP: 'es',
  ITA: 'it',
};

export const getDefaultLanguage = (countryCode) => {
  return get(countryDefaultLanguageMap, `${countryCode}`, 'en');
};

export const GlobalHeaderNavIDs = {
  Solutions: 'e48c1067-e6e5-4061-a6f0-67706ec2624c',
  Insights: 'adf83acf-10b7-4dfd-9b1b-dffcc353ce1d',
  AboutUs: '681a9a0c-c3f8-4ca2-8362-1447491beddf',
  Careers: '92961ae3-8683-4334-a45f-eb0b14df52dc',
};
