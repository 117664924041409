import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CardQuote from '../Cards/CardQuote';
import Slider from 'react-slick';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import {
  getFooterCallOutBarStylesFromBackgroundColor,
  isBrowserDocumentAvailable,
} from '../../../utils/HelperUtils';
import { ComponentDataAttributes } from '../../../constants/gtm';

const CarouselQuotes = ({ variant, cssClass, ...props }) => {
  const {
    fields,
    rendering: { componentName },
  } = props;
  const { Quotes = [], Title, ctaArrow, ctaLink, ctaText, ctaStyle, ctaIconPosition } = fields;

  const settingsSliderNav = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: true,
    cssEase: 'ease-in-out',
    accessibility: true,
    afterChange: () => hideAriaHiddenTiles(),
    customPaging: (i) => (
      <div className="pagination">
        <span className="active">0{i + 1}</span>
        <span>&nbsp;/&nbsp;</span>
        <span className="total">0{Quotes.length}</span>
      </div>
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.2,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          infinite: false,
        },
      },
    ],
  };

  function hideAriaHiddenTiles() {
    if (isBrowserDocumentAvailable) {
      Array.from(document?.querySelectorAll('.slick-slide')).forEach((slide) => {
        slide.style.visibility = slide.classList?.contains('slick-active') ? 'visible' : 'hidden';
        // if (slide.classList.contains('slick-active')) slide.setAttribute('tabindex', -1);
        // else slide.removeAttribute('tabindex');
      });
    }
  }

  useEffect(() => {
    hideAriaHiddenTiles();
  });

  return (
    <section
      className={`carousel carousel__${variant} ${cssClass}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row header-wrapper">
          <div className="col section-header">
            <h2 className="title">{<TextSitecoreField field={Title} />}</h2>
          </div>
          {ctaText && (
            <div className="cta-wrapper col-lg-4">
              <ButtonHref
                text={<TextSitecoreField field={ctaText} />}
                href={ctaLink?.value?.href}
                cssClass=""
                variant={`${
                  ctaStyle && ctaStyle?.fields?.text?.value
                    ? ctaStyle?.fields?.text?.value
                    : 'primary-button'
                }`}
                arrow={ctaArrow && ctaArrow?.value}
                linkType={`${
                  ctaLink && ctaLink.value.linktype ? ctaLink?.value?.linktype : 'internal'
                }`}
                linkFields={`${ctaLink && ctaLink}`}
                iconPosition={`${
                  ctaIconPosition?.fields === 'right' ? 'icon__right' : 'icon__left'
                }`}
                componentName={componentName}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col carousel-wrapper">
            <Slider {...settingsSliderNav}>
              {Quotes &&
                Quotes?.map((item, index) => (
                  <CardQuote
                    key={index}
                    title={item?.fields?.Title}
                    name={item?.fields?.Name}
                    backgroundColor={getFooterCallOutBarStylesFromBackgroundColor(
                      item?.fields?.backgroundColor?.fields?.text?.value
                    )}
                    componentName={componentName}
                  />
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

CarouselQuotes.defaultProps = {
  Title: '',
  ctaText: '',
  ctaUrl: '',
  cssClass: '',
  variant: 'quote',
  Quotes: [],
  fields: {},
};

CarouselQuotes.propTypes = {
  fields: PropTypes.shape({}),
};

export default CarouselQuotes;
