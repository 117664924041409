import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Chevron } from './chevron.svg';
import './dataBlockStatistic.scss';

const DataBlockStatistic = ({ Data }) => {
  const { type, color, size, data, secondaryData } = Data;
  const isNumberCard = type === 'number';
  const isPercentageCard = type === 'percentage';
  const isRankingCard = type === 'ranking';
  const isMedium = size.toLowerCase() === 'm';

  return (
    <section
      className={`db-statistic db-statistic--${type} db-statistic--${color} db-statistic--${size.toLowerCase()} ${
        secondaryData && 'db-statistic--secondary'
      }`}
    >
      <div className="db-statistic__container">
        <div className="db-statistic__primary-container">
          {isNumberCard && <p className="db-statistic__pre-text">{data.preText}</p>}
          <div className="db-statistic__value-container">
            <p className="db-statistic__value">
              {data.statisticValue}
              {isPercentageCard && <span className="db-statistic__percentage">%</span>}
              {isRankingCard && (
                <span className="db-statistic__value-suffix">{data.statisticSuffix}</span>
              )}
            </p>
            {isRankingCard && data.statisticChange !== 0 && (
              <div className="db-statistic__change-container">
                {data.statisticChange > 0 && (
                  <Chevron className="db-statistic__change-icon db-statistic__change-icon--positive" />
                )}
                <p className="db-statistic__change-value">{Math.abs(data.statisticChange)}</p>
                {data.statisticChange < 0 && (
                  <Chevron className="db-statistic__change-icon db-statistic__change-icon--negative" />
                )}
              </div>
            )}
          </div>
          <p className="db-statistic__post-text">{data.postText}</p>
          {secondaryData && isMedium && (
            <div className="db-statistic__secondary-container">
              <div className="db-statistic__secondary-value">{secondaryData.statisticValue}</div>
              <div className="db-statistic__secondary-desc">{secondaryData.description}</div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

DataBlockStatistic.defaultProps = {
  color: 'green',
  size: 'S',
};

DataBlockStatistic.propTypes = {
  type: PropTypes.oneOf(['number', 'percentage', 'ranking']).isRequired,
  color: PropTypes.oneOf(['blue', 'green', 'violet']),
  size: PropTypes.oneOf(['S', 'M']),
  data: PropTypes.object.isRequired,
  secondaryData: PropTypes.object,
};

export default DataBlockStatistic;
