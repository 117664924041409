import React from 'react';
import PropTypes from 'prop-types';
import ImageSitecoreField from '../../components/sitecorefields/ImageSitecoreField';
import { OtherLinkDataAttributes } from '../../constants/gtm';

const UtilityIcon = ({ key, text, img, altText, ctaUrl, ctaTarget, componentName = '' }) => (
  <a
    href={ctaUrl}
    className="utility-icon"
    target={ctaTarget}
    rel="noopener noreferrer nofollow"
    {...OtherLinkDataAttributes({
      name: componentName,
      text: '',
      dataItemName: '',
      url: img?.value?.src || '/',
    })}
    key={key}
  >
    {text}
    <ImageSitecoreField field={img} alt={altText} />
  </a>
);
UtilityIcon.defaultProps = {
  key: '',
  text: '',
  img: '',
  altText: '',
  ctaUrl: '',
  ctaTarget: '_blank',
  componentName: '',
};

UtilityIcon.propTypes = {
  key: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
  altText: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaTarget: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  componentName: PropTypes.string,
};

export default UtilityIcon;
