import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbLink from './BreadcrumbLink';
import { isEmpty } from 'lodash';

/**
 * Renders a breadcrumb component with the given items and CSS class.
 * @param {Object[]} items - The items to be displayed in the breadcrumb.
 * @param {string} items[].breadcrumbDisplayText - The text to be displayed for the breadcrumb item.
 * @param {string} items[].url - The URL to navigate to when the breadcrumb item is clicked.
 * @param {string} cssClass - The CSS class to be applied to the breadcrumb component.
 * @param {string} componentName - The name of the component.
 * @returns {JSX.Element} The breadcrumb component.
 */
const Breadcrumb = ({ items, cssClass, componentName = '', ...props }) => {
  const { textColor = '' } = props;
  const breadcrumbItems = getBreadcrumbValues(items).reverse();

  const breadcrumbValues = breadcrumbItems.map((str) => {
    const [value, url] = str.split(',');
    return { value, url };
  });

  return (
    <ul className={`breadcrumb ${cssClass} ${textColor}`}>
      {breadcrumbValues &&
        breadcrumbValues?.map((item, i) => (
          <BreadcrumbLink
            key={i}
            text={getBreadcrumbDisplayText(item)}
            url={item?.url}
            componentName={componentName}
          />
        ))}
    </ul>
  );
};

Breadcrumb.defaultProps = {
  items: {},
  cssClass: '',
  componentName: '',
  textColor: '',
};

Breadcrumb.propTypes = {
  items: PropTypes.shape({}),
  cssClass: PropTypes.string,
  componentName: PropTypes.string,
  textColor: PropTypes.string,
};

// let breadcrumbArr = [];
// const createBreadCrumb = (object) => {
//   let breadObj = {};
//   if (object) {
//     for (const [key, value] of Object.entries(object)) {
//       if (key === BREADCRUMB_URL_KEY && has(object, BREADCRUMB_DISPLAY_TEXT)) {
//         createBreadCrumb(object[key]);
//         delete object[key];
//         breadcrumbArr.push(object);
//       } else {
//         breadObj[key] = object[key];
//       }
//     }
//     breadcrumbArr.push(breadObj);
//     // Remove duplicates
//     breadcrumbArr = breadcrumbArr.filter(
//       (value, index, self) =>
//         index ===
//         self.findIndex((t) => {
//           return (
//             t.breadcrumbDisplayText === value.breadcrumbDisplayText &&
//             t.breadcrumbDisplayText === value.breadcrumbDisplayText
//           );
//         })
//     );
//   }
//   return breadcrumbArr;
// };

const getBreadcrumbValues = (obj) => {
  const values = [];
  if (obj.breadcrumbDisplayText && obj.breadcrumbDisplayText.value) {
    values.push(`${obj.breadcrumbDisplayText.value},${obj.url}`);
  }
  if (obj.parent) {
    values.push(...getBreadcrumbValues(obj.parent));
  }
  return values;
};

const getBreadcrumbDisplayText = (item = {}) => {
  if (item?.value) {
    if (item?.value && !isEmpty(item?.value)) {
      return item?.value;
    }
    return item?.value;
  }
  return null;
};

export default Breadcrumb;
