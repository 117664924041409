import React from 'react';
import PropTypes from 'prop-types';

const CardsforFrame = ({ Data }) => {
  const { title, subtitle, description } = Data;
  return (
    <div className={'card_frame'}>
      <div className="card-body">
        {subtitle && <h5 className="sub-title">{subtitle}</h5>}
        {title && <h3 className="title">{title}</h3>}
        {description && <h6 className="title">{description}</h6>}
      </div>
    </div>
  );
};

CardsforFrame.defaultProps = {
  subtitle: '96%',
  title: 'Lorem ipsum dolor sit amet.',
  description: '',
  backgroundColor: 'secondary',
};

CardsforFrame.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  backgroundColor: PropTypes.oneOf(['primary', 'secondary']),
};

export default CardsforFrame;
