import React, { useEffect, useState } from 'react';
import DataBlockStatistic from './DataBlockStatistic';
import DataBlock from './DataBlock/DataBlock';
import CardsforFrame from './CardsforFrame';
import './FramDataBlock.scss';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../../scripts/breakpoints';
import TextSitecoreField from '../../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../../sitecorefields/ImageSitecoreField';
import axios from 'axios';
import { sitecoreHostUrl } from '../../../../utils/HelperUtils';

function FrameDataBlock(props) {
  const [respData1, setRespData1] = useState();
  const [propsData, setPropsData] = useState();

  useEffect(() => {
    apiGet();
  }, []);

  const apiGet = async () => {
    try {
      const url = `${sitecoreHostUrl}/api/json/${props?.fields?.ApiId?.value}`;
      const apiCall = await axios.get(url);
      const apiRes = await apiCall?.data;
      setRespData1(apiRes);
      setPropsData(variable(apiRes));
    } catch (error) {}
  };

  let desktopWidth = useMediaQuery({ maxWidth: 1024 });

  const isTiny = useMediaQuery({ maxWidth: breakpoints.xxs - 1 });
  const isMobile = useMediaQuery({
    minWidth: breakpoints.xxs,
    maxWidth: breakpoints.xs - 1,
  });
  const isTablet = useMediaQuery({
    minWidth: breakpoints.xs,
    maxWidth: breakpoints.xl,
  });
  const getSize = () => {
    if (isTiny) {
      return 'Tiny';
    } else if (isMobile) {
      return 'XXS';
    } else if (isTablet) {
      return 'S';
    }
    return 'M';
  };

  const { fields } = props;
  let tabTitles = fields?.Tabs?.map((item) => item.fields.TabTitle);
  let tabIcon = fields?.Tabs?.map((item) => item.fields.TabIcon);
  let chartTitle = fields?.Tabs?.map((item) => item.fields.Description.value);

  let chartTitles = fields?.Tabs?.map((item) =>
    item.fields.ChartHeadingList.map((item2) => item2.fields?.chartHeading?.value)
  );

  let Description_Reg = fields?.Tabs[1]?.fields?.Description;

  const variable = (respData1) => {
    let chartTitle1 = chartTitles[0];
    let chartTitle2 = chartTitles[1];
    let chartTitle3 = chartTitles[2];
    let chartTitle4 = chartTitles[3];

    let dounghtData = [
      respData1?.Availability?.babyBoomersValue,
      respData1?.Availability?.genXValue,
      respData1?.Availability?.genYValue,
      respData1?.Availability?.genZValue,
      respData1?.Availability?.Other,
    ];

    let dounghtDataVal = dounghtData.map((e) => e.slice(0, -1));

    let parameter = {
      PrimaryDetails: {
        statistic1: {
          color: 'violet',
          data: {
            postText: respData1?.PrimaryDetails?.totalWorkforceIndexGlobalRankingLabel,
            statisticChange: 0,
            statisticSuffix:
              respData1?.PrimaryDetails?.totalWorkforceIndexGlobalRankingValue.slice(-2),
            statisticValue: respData1?.PrimaryDetails?.totalWorkforceIndexGlobalRankingValue.slice(
              0,
              respData1?.PrimaryDetails?.totalWorkforceIndexGlobalRankingValue.length - 2
            ),
          },
          size: 'M',
          type: 'ranking',
        },
        statistic2: {
          color: 'violet',
          data: {
            postText: respData1?.PrimaryDetails?.totalWorkforceIndexRegionalRankingLabel,
            statisticChange: 0,
            statisticSuffix:
              respData1?.PrimaryDetails?.totalWorkforceIndexRegionalRankingValue.slice(-2),
            statisticValue:
              respData1?.PrimaryDetails?.totalWorkforceIndexRegionalRankingValue.slice(
                0,
                respData1?.PrimaryDetails?.totalWorkforceIndexRegionalRankingValue.length - 2
              ),
          },
          size: 'M',
          type: 'ranking',
        },
        statistic3: {
          color: 'violet',
          data: {
            postText: respData1?.PrimaryDetails?.totalWorkforceIndexRemoteReadinessRankingLabel,
            statisticChange: 0,
            statisticSuffix:
              respData1?.PrimaryDetails?.totalWorkforceIndexRemoteReadinessRankingValue.slice(-2),
            statisticValue:
              respData1?.PrimaryDetails?.totalWorkforceIndexRemoteReadinessRankingValue.slice(
                0,
                respData1?.PrimaryDetails?.totalWorkforceIndexRemoteReadinessRankingValue.length - 2
              ),
          },
          size: 'M',
          type: 'ranking',
        },
        liner1: {
          chartType: 'linear-gauge',
          color: 'blue-violet',
          size: 'XS',
          data: {
            datasets: [
              {
                data: [respData1?.PrimaryDetails?.regulationValue],
                label: respData1?.PrimaryDetails?.regulationLabel,
              },
              {
                data: [respData1?.PrimaryDetails?.maturityValue],
                label: respData1?.PrimaryDetails?.maturityLabel,
              },
            ],
            labels: ['Average Work Days'],
            xValues: {
              max:
                Math.max(
                  ...[
                    respData1?.PrimaryDetails?.regulationValue,
                    respData1?.PrimaryDetails?.maturityValue,
                  ].map((i) => Number(i))
                ) * 1.5,
              min: 0,
            },
          },
        },
        card1: {
          title: respData1?.PrimaryDetails?.remoteReadiness1RemoteworksuitabilityValue,
          subtitle: respData1?.PrimaryDetails?.remoteReadiness1RemoteworksuitabilityLabel,
          description: '',
        },
        card2: {
          title: respData1?.PrimaryDetails?.remoteReadiness2UNeparticipationlevelValue,
          subtitle: respData1?.PrimaryDetails?.remoteReadiness2UNeparticipationlevelLabel,
          description: '',
        },
        card3: {
          title: respData1?.PrimaryDetails?.remoteReadiness3IndividualsusinginternetValue,
          subtitle: respData1?.PrimaryDetails?.remoteReadiness3IndividualsusinginternetLabel,
          description: '',
        },
        card4: {
          title: respData1?.PrimaryDetails?.remoteReadiness4HouseholdswcomputerValue,
          subtitle: respData1?.PrimaryDetails?.remoteReadiness4HouseholdswcomputerLabel,
          description: '',
        },
        statistic4: {
          color: 'blue',
          data: {
            postText:
              respData1?.PrimaryDetails?.informalWorkforceasapercentageoftotalworkforceLabel,
            statisticValue:
              respData1?.PrimaryDetails?.informalWorkforceasapercentageoftotalworkforceValue.slice(
                0,
                respData1?.PrimaryDetails?.informalWorkforceasapercentageoftotalworkforceValue
                  .length - 1
              ),
          },
          size: 'M',
          type: 'percentage',
        },
      },
      Availablity: {
        doughnut: {
          chartType: 'doughnut',
          size: getSize(),
          data: {
            datasets: [
              {
                borderColor: 'transparent',
                borderWidth: 0,
                data: dounghtDataVal,
                fill: 'start',
                label: 'Net Secondary Education',
              },
            ],
            labels: [
              respData1?.Availability?.babyBoomersLabel,
              respData1?.Availability?.genXLabel,
              respData1?.Availability?.genYLabel,
              respData1?.Availability?.genZLabel,
              'Other',
            ],
          },
          descLabel: respData1?.Availability?.totalWorkforceLabel,
          description: respData1?.Availability?.totalWorkforceValue,
          title: chartTitle1[0],
        },
        card5: {
          title: respData1?.Availability?.englishProficiencyValue,
          subtitle: respData1?.Availability?.englishProficiencyLabel,
          description: '',
        },
        card6: {
          title: respData1?.Availability?.percentageHighlySkilledValue,
          subtitle: respData1?.Availability?.percentageHighlySkilledLabel,
          description: '',
        },
        card7: {
          title: respData1?.Availability?.netSecondaryEducationValue,
          subtitle: respData1?.Availability?.netSecondaryEducationLabel,
          description: '',
        },
        card8: {
          title: respData1?.Availability?.reportedliteracyrateValue,
          subtitle: respData1?.Availability?.reportedliteracyrateLabel,
          description: '',
        },
        card9: {
          title: respData1?.Availability?.genderGapValue,
          subtitle: respData1?.Availability?.genderGapLabel,
          description: '',
        },
        card10: {
          title: respData1?.Availability?.ratioofRDworkerstoevery1000inworkforceValue,
          subtitle: respData1?.Availability?.ratioofRDworkerstoevery1000inworkforceLabel,
          description: '',
        },
      },
      Regulation: {
        bar1: {
          chartType: 'bar',
          size: 'XS',
          data: {
            datasets: [
              {
                barPercentage: 1,
                borderRadius: 100,
                categoryPercentage: 0.8,
                data: [
                  respData1?.Regulation?.country1yeartenureValue,
                  respData1?.Regulation?.globalAverage1yeartenureValue,
                ],
                label: 'Workforce rankings',
              },
            ],
            labels: [
              respData1?.Regulation?.country1yeartenureLabel.split('(')[0],
              respData1?.Regulation?.globalAverage1yeartenureLabel.split('(')[0],
            ],
            yValues: {
              max:
                Math.max(
                  ...[
                    respData1?.Regulation?.country1yeartenureValue,
                    respData1?.Regulation?.globalAverage1yeartenureValue,
                  ].map((i) => Number(i))
                ) * 1.2,
              min: 0,
            },
          },
          title: chartTitle2[0],
        },
        bar2: {
          chartType: 'bar',
          size: 'XS',
          data: {
            datasets: [
              {
                barPercentage: 1,
                borderRadius: 100,
                categoryPercentage: 0.8,
                data: [
                  respData1?.Regulation?.country5yeartenureValue,
                  respData1?.Regulation?.globalAverage5yeartenureValue,
                ],
                label: 'Workforce rankings',
              },
            ],
            labels: [
              respData1?.Regulation?.country5yeartenureLabel.split('(')[0],
              respData1?.Regulation?.globalAverage5yeartenureLabel.split('(')[0],
            ],
            yValues: {
              max:
                Math.max(
                  ...[
                    respData1?.Regulation?.country5yeartenureValue,
                    respData1?.Regulation?.globalAverage5yeartenureValue,
                  ].map((i) => Number(i))
                ) * 1.2,
              min: 0,
            },
          },
          title: chartTitle2[1],
        },
        bar7: {
          chartType: 'bar',
          size: 'XS',
          data: {
            datasets: [
              {
                barPercentage: 1,
                borderRadius: 100,
                categoryPercentage: 0.8,
                data: [
                  respData1?.Regulation?.country10yeartenureValue,
                  respData1?.Regulation?.globalAverage10yeartenureValue,
                ],
                label: 'Workforce rankings',
              },
            ],
            labels: [
              respData1?.Regulation?.country10yeartenureLabel.split('(')[0],
              respData1?.Regulation?.globalAverage10yeartenureLabel.split('(')[0],
            ],
            yValues: {
              max:
                Math.max(
                  ...[
                    respData1?.Regulation?.country10yeartenureValue,
                    respData1?.Regulation?.globalAverage10yeartenureValue,
                  ].map((i) => Number(i))
                ) * 1.2,
              min: 0,
            },
          },
          title: chartTitle2[1],
        },
        card11: {
          title: respData1?.Regulation?.subcontractallowedValue,
          subtitle: respData1?.Regulation?.subcontractallowedLabel,
          description: '',
        },
        card12: {
          title: respData1?.Regulation?.maximumContractDurationValue,
          subtitle: respData1?.Regulation?.maximumContractDurationLabel,
          description: '',
        },
        card13: {
          title: respData1?.Regulation?.cybersecurityGlobalRankValue,
          subtitle: respData1?.Regulation?.cybersecurityGlobalRankLabel,
          description: '',
        },
        card14: {
          title: respData1?.Regulation?.cybersecurityRegionalRankValue,
          subtitle: respData1?.Regulation?.cybersecurityRegionalRankLabel,
          description: '',
        },
        card15: {
          title: respData1?.Regulation?.indexofEconomicFreedomValue,
          subtitle: respData1?.Regulation?.indexofEconomicFreedomLabel,
          description: '',
        },
      },
      costeffeciency: {
        bar3: {
          chartType: 'bar',
          size: getSize(),
          data: {
            datasets: [
              {
                barPercentage: 1,
                borderRadius: 100,
                categoryPercentage: 0.8,
                data: [
                  respData1?.CostEfficiency?.averageMonthlyWageReportedAveragewageinUSDValue,
                  respData1?.CostEfficiency
                    ?.averageMonthlyWageReportedRegionalAveragewageinUSDValue,
                  respData1?.CostEfficiency?.averageMonthlyWageReportedGlobalAveragewageinUSDValue,
                ],
                label: 'Workforce rankings',
              },
            ],
            labels: [
              respData1?.CostEfficiency?.averageMonthlyWageReportedAveragewageinUSDLabel,
              respData1?.CostEfficiency?.averageMonthlyWageReportedRegionalAveragewageinUSDLabel,
              respData1?.CostEfficiency?.averageMonthlyWageReportedGlobalAveragewageinUSDLabel,
            ],
            yValues: {
              max:
                Math.max(
                  ...[
                    respData1?.CostEfficiency?.averageMonthlyWageReportedAveragewageinUSDValue,
                    respData1?.CostEfficiency
                      ?.averageMonthlyWageReportedRegionalAveragewageinUSDValue,
                    respData1?.CostEfficiency
                      ?.averageMonthlyWageReportedGlobalAveragewageinUSDValue,
                  ].map((i) => Number(i))
                ) * 1.2,
              min: 0,
            },
          },
          title: chartTitle3[0],
        },
        card16: {
          title: respData1?.CostEfficiency?.reportedCostofDoingBusinessValue,
          subtitle: respData1?.CostEfficiency?.reportedCostofDoingBusinessLabel,
          description: '',
        },
        card17: {
          title: respData1?.CostEfficiency?.isEqualPayforEqualWorkMandatedValue,
          subtitle: respData1?.CostEfficiency?.isEqualPayforEqualWorkMandatedLabel,
          description: '',
        },
        card18: {
          title: respData1?.CostEfficiency?.parityRequirementsValue,
          subtitle: respData1?.CostEfficiency?.parityRequirementsLabel,
          description: '',
        },
      },
      Productivity: {
        bar4: {
          chartType: 'bar',
          size: 'XS',
          data: {
            datasets: [
              {
                barPercentage: 1,
                borderRadius: 100,
                categoryPercentage: 0.8,
                data: [
                  respData1?.Productivity?.leaveAfter5YearsCountryValue,
                  respData1?.Productivity?.leaveAfter5YearsRegionValue,
                ],
                label: 'Workforce rankings',
              },
            ],
            labels: [
              respData1?.Productivity?.leaveAfter5YearsCountryLabel.split('-')[1],
              respData1?.Productivity?.leaveAfter5YearsRegionLabel.split('-')[1],
            ],
            yValues: {
              max:
                Math.max(
                  ...[
                    respData1?.Productivity?.leaveAfter5YearsCountryValue,
                    respData1?.Productivity?.leaveAfter5YearsRegionValue,
                  ].map((i) => Number(i))
                ) * 1.2,
              min: 0,
            },
          },
          title: chartTitle4[0],
        },
        bar5: {
          chartType: 'bar',
          size: 'XS',
          data: {
            datasets: [
              {
                barPercentage: 1,
                borderRadius: 100,
                categoryPercentage: 0.8,
                data: [
                  respData1?.Productivity?.techReadinessCountryValue,
                  respData1?.Productivity?.techReadinessRegionValue,
                ],
                label: 'Workforce rankings',
              },
            ],
            labels: [
              respData1?.Productivity?.techReadinessCountryLabel.split('-')[1],
              respData1?.Productivity?.techReadinessRegionLabel.split('-')[1],
            ],
            yValues: {
              max:
                Math.max(
                  ...[
                    respData1?.Productivity?.techReadinessCountryValue,
                    respData1?.Productivity?.techReadinessRegionValue,
                  ].map((i) => Number(i))
                ) * 1.2,
              min: 0,
            },
          },
          title: chartTitle4[1],
        },
        bar6: {
          chartType: 'bar',
          size: 'XS',
          data: {
            datasets: [
              {
                barPercentage: 1,
                borderRadius: 100,
                categoryPercentage: 0.8,
                data: [
                  respData1?.Productivity?.maximumProbabtionaryPeriodmonthsCountryValue,
                  respData1?.Productivity?.maximumProbabtionaryPeriodmonthsRegionValue,
                ],
                label: 'Workforce rankings',
              },
            ],
            labels: [
              respData1?.Productivity?.maximumProbabtionaryPeriodmonthsCountryLabel.split('-')[1],
              respData1?.Productivity?.maximumProbabtionaryPeriodmonthsRegionLabel.split('-')[1],
            ],
            yValues: {
              max:
                Math.max(
                  ...[
                    respData1?.Productivity?.maximumProbabtionaryPeriodmonthsCountryValue,
                    respData1?.Productivity?.maximumProbabtionaryPeriodmonthsRegionValue,
                  ]
                ) * 1.2,
              min: 0,
            },
          },
          title: chartTitle4[2],
        },
        liner2: {
          chartType: 'linear-gauge',
          color: 'blue-violet',
          size: 'S',
          data: {
            datasets: [
              {
                data: [respData1?.Productivity?.averageworkweekhoursCountryValue],
                label: `${respData1?.Productivity?.averageworkweekhoursCountryValue} - hours`,
              },
              {
                data: [respData1?.Productivity?.averageworkweekhoursRegionValue],
                label: `${respData1?.Productivity?.averageworkweekhoursRegionValue} - hours`,
              },
            ],
            labels: [
              respData1?.Productivity?.averageworkweekhoursCountryLabel.split('-')[1],
              respData1?.Productivity?.averageworkweekhoursRegionLabel.split('-')[1],
              // chartTitle4[3],
            ],
            xValues: {
              max: 48,
              min: 0,
            },
          },
        },
        liner3: {
          chartType: 'linear-gauge',
          color: 'blue-violet',
          size: 'S',
          data: {
            datasets: [
              {
                data: [respData1?.Productivity?.averageworkdayhoursCountryValue],
                label: `${respData1?.Productivity?.averageworkdayhoursCountryValue} - hours`,
              },
              {
                data: [respData1?.Productivity?.averageworkdayhoursRegionValue],
                label: `${respData1?.Productivity?.averageworkdayhoursRegionValue} - hours`,
              },
            ],
            labels: [
              respData1?.Productivity?.averageworkdayhoursCountryLabel.split('-')[1],
              respData1?.Productivity?.averageworkdayhoursRegionLabel.split('-')[1],
            ],
            // labels: [chartTitle4[4], chartTitle4[4]],
            xValues: {
              max: 9,
              min: 0,
            },
          },
        },
        card19: {
          title: respData1?.Productivity?.majorRestrictionsonNightworkValue,
          subtitle: respData1?.Productivity?.majorRestrictionsonNightworkLabel,
          description: '',
        },
        card20: {
          title: respData1?.Productivity?.majorRestrictionsonworkonHolidaysValue,
          subtitle: respData1?.Productivity?.majorRestrictionsonworkonHolidaysLabel,
          description: '',
        },
      },
    };
    return parameter;
  };

  let column_2 = !desktopWidth ? 'col-2' : '';
  let column_3 = !desktopWidth ? 'col-3' : '';
  let column_4 = !desktopWidth ? 'col-4' : '';
  let column_5 = !desktopWidth ? 'col-5' : '';
  let column_6 = !desktopWidth ? 'col-6' : '';
  let column_7 = !desktopWidth ? 'col-7' : '';
  let column_8 = !desktopWidth ? 'col-8' : '';
  let column_9 = !desktopWidth ? 'col-9' : '';
  let column_12 = !desktopWidth ? 'col-12' : '';

  return (
    <>
      {propsData?.Availablity?.doughnut && (
        <div className="FramDataBlock_Container" id="FramDataBlock_Container">
          <div className="container-xl">
            <div className="row">
              <h3>Total Work Force Index</h3>
              <div className="threeRankChart">
                <div className={`${column_12} newContainer`}>
                  <div className="block-row">
                    <DataBlockStatistic Data={propsData?.PrimaryDetails?.statistic1} />
                    <DataBlockStatistic Data={propsData?.PrimaryDetails?.statistic2} />
                    <DataBlockStatistic Data={propsData?.PrimaryDetails?.statistic3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-xl">
            <div className="row">
              <div className="fourCard_chart">
                <div className={`${column_9}`}>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.PrimaryDetails?.card1} />
                    <CardsforFrame Data={propsData?.PrimaryDetails?.card2} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.PrimaryDetails?.card3} />
                    <CardsforFrame Data={propsData?.PrimaryDetails?.card4} />
                  </div>
                </div>
                <div className={`${column_3} newContainer`}>
                  <div>
                    <DataBlockStatistic Data={propsData?.PrimaryDetails?.statistic4} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Headings">
            <div className="TitleIcon">
              <ImageSitecoreField field={tabIcon[0]} />
            </div>
            <h2>{<TextSitecoreField field={tabTitles[0]} />}</h2>
          </div>
          <div>
            <div className="container-xl">
              <div className="row">
                <div className="donught_Container">
                  <div className={`${column_7} newContainer`}>
                    <DataBlock Data={propsData?.Availablity?.doughnut} />
                  </div>
                  <div className={`${column_5}`}>
                    <div className="card-wraper">
                      <CardsforFrame Data={propsData?.Availablity?.card5} />
                      <CardsforFrame Data={propsData?.Availablity?.card6} />
                    </div>
                    <div className="card-wraper">
                      <CardsforFrame Data={propsData?.Availablity?.card7} />
                      <CardsforFrame Data={propsData?.Availablity?.card8} />
                    </div>
                    <div className="card-wraper">
                      <CardsforFrame Data={propsData?.Availablity?.card9} />
                      <CardsforFrame Data={propsData?.Availablity?.card10} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Headings">
            <div className="TitleIcon">
              <ImageSitecoreField field={tabIcon[1]} />
            </div>
            <h2>{<TextSitecoreField field={tabTitles[1]} />}</h2>
          </div>

          <div className="Description">
            <p>{<TextSitecoreField field={Description_Reg} />}</p>
          </div>
          <div className="container-xl">
            <div className="row">
              <div className="twoBarContainer">
                <div className={`${column_4} newContainer`}>
                  <DataBlock Data={propsData?.Regulation?.bar1} />
                </div>
                <div className={`${column_4} newContainer`}>
                  <DataBlock Data={propsData?.Regulation?.bar7} />
                </div>
                <div className={`${column_4}`}>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Regulation?.card11} />
                    <CardsforFrame Data={propsData?.Regulation?.card12} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Regulation?.card13} />
                    <CardsforFrame Data={propsData?.Regulation?.card14} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Regulation?.card15} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Headings">
            <div className="TitleIcon">
              <ImageSitecoreField field={tabIcon[2]} />
            </div>
            <h2>{<TextSitecoreField field={tabTitles[2]} />}</h2>
          </div>
          <div className="container-xl">
            <div className="row">
              <div className="twoBarContainer barchartCanvas">
                <div className={`${column_7} newContainer`}>
                  <DataBlock Data={propsData?.costeffeciency?.bar3} />
                </div>

                <div className={`${column_5}`}>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.costeffeciency?.card17} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.costeffeciency?.card18} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Headings">
            <div className="TitleIcon">
              <ImageSitecoreField field={tabIcon[3]} />
            </div>
            <h2>{<TextSitecoreField field={tabTitles[3]} />}</h2>
          </div>
          <div>
            <div className="container-xl">
              <div className="row">
                <div className="threeBarContainer">
                  <div className={`${column_4} newContainer`}>
                    <DataBlock Data={propsData?.Productivity?.bar4} />
                  </div>
                  <div className={`${column_4} newContainer`}>
                    <DataBlock Data={propsData?.Productivity?.bar5} />
                  </div>
                  <div className={`${column_4}  newContainer`}>
                    <DataBlock Data={propsData?.Productivity?.bar6} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="container-xl">
              <div className="row">
                <div className="twoLineContainer">
                  <div className={`${column_5} newContainer`}>
                    <div className="liner_title">
                      <div>
                        <h4 className="linerChart_title">{chartTitles[3][3]}</h4>
                      </div>
                      <div>
                        <DataBlock Data={propsData?.Productivity?.liner2} />
                      </div>
                    </div>
                  </div>
                  <div className={`${column_5} newContainer`}>
                    <div className="liner_title">
                      <div>
                        <h4 className="linerChart_title">{chartTitles[3][4]}</h4>
                      </div>
                      <div>
                        <DataBlock Data={propsData?.Productivity?.liner3} />
                      </div>
                    </div>
                  </div>
                  <div className={`${column_2}`}>
                    <div className="card-wraper">
                      <CardsforFrame Data={propsData?.Productivity?.card19} />
                    </div>
                    <div className="card-wraper">
                      <CardsforFrame Data={propsData?.Productivity?.card20} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FrameDataBlock;
