import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import Tabs from '../SubNav/SubNavTabs';
import Tab from '../SubNav/SubNavTabItem';
import Panel from '../SubNav/SubNavPanel';
import AccordionTab from '../Accordion/AccordionTab';
import TextSitecoreField from '../../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../../constants/gtm';

const ExpandableGrid = ({ subTitle, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  //   const {
  //     fields,
  //     rendering: { componentName = '' },
  //   } = props;

  const { fields } = props;
  const {
    BlockHeading,
    BlockContent,
    NavigationTabs,
    backgroundTextColor,
    ChartHeading,
    Title,
    Description,
  } = fields;
  const backgroundColor = backgroundTextColor?.fields?.text?.value
    ? backgroundTextColor?.fields?.text?.value.toLowerCase()
    : 'primary';

  return (
    <section
      className={'subnavexpandable-section solutions secondary'}
      {...ComponentDataAttributes('NavigationExpandableGrid')}
    >
      <div className="container-xl">
        <div className="row section-header">
          <div className="col-lg-12 grid-title">
            <h2>
              <TextSitecoreField field={BlockHeading}></TextSitecoreField>
            </h2>
            {BlockContent?.value && (
              <p>{<TextSitecoreField field={BlockContent}></TextSitecoreField>}</p>
            )}
          </div>
        </div>
        {isDesktop && (
          <Tabs defaultActiveTab={NavigationTabs && NavigationTabs[0] && NavigationTabs[0].name}>
            {NavigationTabs &&
              NavigationTabs.map((item) => {
                return (
                  <Tab key={item.id} name={item.name} icon={item.fields.TabIcon.value.src}>
                    <Panel
                      title={item?.fields?.Title}
                      text={item?.fields?.Description}
                      id={item?.fields?.Link?.value?.id}
                      ctaText={item?.fields?.CTAText?.value}
                      ctaLink={item?.fields?.CTALink}
                      icon={item?.fields?.TabIcon?.value}
                      // linkHeading={item?.fields?.linkHeading}
                      // links={item?.fields?.linkCollection}
                      APIID={item?.fields?.TabApiId?.value}
                      name={item.name}
                      ChartHeading={ChartHeading?.value}
                      backgroundColor="secondary"
                      cssClass=""
                    />
                  </Tab>
                );
              })}
          </Tabs>
        )}
        {isMobile && (
          <AccordionTab
            items={NavigationTabs}
            backgroundColor="secondary"
            ChartHeading={ChartHeading?.value}
          />
        )}
        <div className="row section-footer">
          <div className="col-lg-12 grid-footer">
            <h5>
              <TextSitecoreField field={Title}></TextSitecoreField>
            </h5>
            {BlockContent?.value && (
              <p>{<TextSitecoreField field={Description}></TextSitecoreField>}</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ExpandableGrid.defaultProps = {
  fields: {
    title: '',
    solutions: [],
  },
  subTitle: '',
  backgroundColor: '',
};

ExpandableGrid.defaultProps = {
  fields: PropTypes.shape({
    solutions: PropTypes.array,
    title: PropTypes.string,
  }),
  subTitle: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundTextColor: PropTypes.shape({
    fields: PropTypes.object,
  }),
};

export default ExpandableGrid;
