import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { filterKeyEnter, checkJSSObject, checkHref } from '../../../utils/HelperUtils';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import { OtherLinkDataAttributes } from '../../../constants/gtm';

const CardServices = ({
  title,
  text,
  icon,
  altText,
  ctaText,
  href,
  cssClass,
  isActive,
  onShow,
  linkFields,
  componentName = '',
}) => {
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });

  const isJSSObject = checkJSSObject(href);
  const isHrefVlaue = isJSSObject ? checkHref(href) : href;
  const divRender = (
    <div className={'card solution'}>
      <div className="card-body">
        <div className={isMobileScreen ? 'card-header m-0' : 'card-header'}>
          <ImageSitecoreField field={icon} alt={altText} className="icon" />

          {isMobileScreen ? (
            title &&
            title?.value && (
              <h2 className="title">
                <TextSitecoreField field={title} />
              </h2>
            )
          ) : (
            <h2 className="title">
              <TextSitecoreField field={title} />
            </h2>
          )}
        </div>

        <div className="card-content">
          <h3 className="title">
            <TextSitecoreField field={title} />
          </h3>
          <p className="description">
            <TextSitecoreField field={text} />
          </p>
          <ButtonHref
            variant="text-button"
            fontSize="small"
            text={ctaText}
            href={href}
            linkType={linkFields?.value?.linktype}
            linkFields={linkFields}
            dataItemName={title?.value}
            componentName={componentName}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`card-wrapper callout callout__${cssClass} ${isActive ? 'active' : ''}`}
      onClick={onShow}
    >
      {isMobileScreen ? (
        divRender
      ) : (
        <div
          className="card-solution-anchor"
          to={isHrefVlaue}
          target={linkFields?.value?.target || linkFields?.target}
          {...OtherLinkDataAttributes({
            name: componentName,
            text: isHrefVlaue || '/',
            dataItemName: (title && title?.value) || '',
          })}
        >
          {divRender}
        </div>
      )}
    </div>
  );
};

CardServices.defaultProps = {
  title: '',
  text: '',
  icon: '',
  altText: '',
  ctaText: '',
  cssClass: '',
};

CardServices.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  altText: PropTypes.string,
  ctaText: PropTypes.string,
  cssClass: PropTypes.oneOf([]),
};

export default CardServices;
