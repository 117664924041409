import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../globals/buttons/Button';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { OtherLinkDataAttributes } from '../../../constants/gtm';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import ButtonHref from '../../../globals/buttons/ButtonHref';

const CardServiceGroup = ({
  title,
  subTitle,
  text,
  icon,
  ctaText,
  isActive,
  onShow,
  link,
  linkType,
  linkFields,
  componentName = '',
  dataItemName = '',
}) => {
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });
  return (
    <div
      className={`card service service-group ${isActive ? 'active' : ''}`}
      onClick={isMobileScreen ? onShow : undefined}
      // {...accessibleOnClick(onShow)}
    >
      {!isMobileScreen ? (
        <div className="card-body">
          <div className="card-header no-padding">
            {icon && <ImageSitecoreField field={icon} />}
            {isMobileScreen && title?.value && (
              <h3 className="title">
                <TextSitecoreField field={title} />
              </h3>
            )}
          </div>
          <div className="card-content-service no-padding-bottom">
            {title?.value && (
              <h3 className="title">
                <TextSitecoreField field={title} />
              </h3>
            )}

            {subTitle?.value && (
              <p className="">
                <RichTextSitecoreField field={subTitle} className="sub-title-service" />
              </p>
            )}

            {text?.value && (
              <p>
                <TextSitecoreField field={text} />
              </p>
            )}
            <ButtonHref
              variant="text-button"
              fontSize="small"
              text={ctaText}
              href={link}
              linkType={linkType}
              linkFields={linkFields}
              cssClass={'black'}
              arrow={false}
              componentName={componentName}
              dataItemName={title?.value}
            />
          </div>
        </div>
      ) : (
        <div className="card-body">
          <div className="card-header">
            {icon && <ImageSitecoreField field={icon} />}
            {isMobileScreen && title?.value && (
              <h3 className="title">
                <TextSitecoreField field={title} />
              </h3>
            )}
          </div>
          <div className="card-content-service">
            {title?.value && (
              <h3 className="title">
                <TextSitecoreField field={title} />
              </h3>
            )}
            {subTitle?.value && (
              <p className="sub-title-service">
                <RichTextSitecoreField field={subTitle} className="sub-title-service" />
              </p>
            )}
            {text?.value && (
              <p>
                <TextSitecoreField field={text} />
              </p>
            )}
            <ButtonHref
              text={ctaText}
              href={link}
              variant="text-button"
              arrow={false}
              componentName={componentName}
              dataItemName={title?.value}
            />
          </div>
        </div>
      )}
    </div>
  );
};

CardServiceGroup.defaultProps = {
  title: '',
  text: '',
  icon: '',
  altText: '',
  serviceProvider: '',
  ctaText: '',
  cssClass: '',
  dataItemName: '',
};

CardServiceGroup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  altText: PropTypes.string,
  serviceProvider: PropTypes.string,
  ctaText: PropTypes.string,
  cssClass: PropTypes.string,
  dataItemName: PropTypes.string,
};

export default CardServiceGroup;
